import React from 'react';
import { Container } from 'react-bootstrap';
import Logo from '../img/logo-updated-2.png';
import '../App.css';

const NotFoundPage = () => {
    return (
        <Container className="d-flex flex-column align-items-center justify-content-center vh-100 text-center">
            <img src={Logo} className='footer-logo mb-4' alt="Logo" />
            <p className="lead">The page you are looking for could not be found.</p>
            <a href="/" className="btn btn-theme-green rounded-pill px-3 py-2 mt-3">
                Go to Home
            </a>
        </Container>
    );
};

export default NotFoundPage;
