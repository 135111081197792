import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import NavigationBar from './NavBar';
import Footer from './Footer';
import '../App.css';
import { CSSTransition } from 'react-transition-group';
import InquiryForm from './InquiryForm';
import Individual from '../img/individual.jpg';
import Couple from '../img/couple.jpg';
import Family from '../img/family.jpg';
import Kylie1 from '../img/photo_1-no-bg.png';
import Kylie1Alt from '../img/photo1.jpg';
import AboutSmall from '../img/photo2.jpg';
import FaqSmall from '../img/counselling-img.jpg';
import CounsellingIndv from '../img/individual-3.jpg';
import CounsellingCpl from '../img/services-img.jpg';
import ACA from '../img/ACA-Members-Logo.jpg';
import PACFA from '../img/PACFA-logo-long.png';
import Plate from '../img/kintsugi-plate.png';
import Appointment from '../img/photo3.jpg';
import P4 from '../img/photo4.jpg';
import P5 from '../img/photo5.jpg';
import P6 from '../img/photo6.jpg';

import AOS from 'aos';
import 'aos/dist/aos.css';

const HomePage = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    const [showMoreIndividual, setShowMoreIndividual] = useState(false);
    const [showMoreCouple, setShowMoreCouple] = useState(false);
    const [showMoreFamily, setShowMoreFamily] = useState(false);

    return (
        <>

            <NavigationBar />
            <section id="home" className="header-section d-flex align-items-end bg-home pt-3 pt-lg-0 py-lg-5">
                <div className='d-flex justify-content-center'>
                    <Row className="d-flex flex-md-row pb-5 pb-lg-0">
                        <Col xs={12} lg={6} xxl={7} className="d-flex flex-column justify-content-center">
                            <div className='text-white lead-normal header-content pt-0 pt-md-5 pt-lg-0'>
                                <img className="header-kylie-image-small" src={Kylie1Alt} alt='Kylie' />
                                <Container fluid>
                                    <h1 className='header-text pt-4 pt-lg-0'>Welcome to Kintsugi Counselling</h1>
                                    <p className=''>My name is Kylie Bolton, a registered counsellor with a special interest in relationships. I work with both individuals and couples that are:</p>
                                    <ul>
                                        <li>experiencing relationship difficulties,</li>
                                        <li>wanting to strengthen their relationships,</li>
                                        <li>or wanting help to gently, respectfully dissolve a relationship.</li>
                                    </ul>
                                    <p className='pt-2'>At Kintsugi Counselling we are dedicated to guiding both individuals and couples through their challenges, fostering healing, resilience, and transformation.</p>
                                    <p>The quality of our interpersonal relationships greatly impact our emotional health and wellbeing and when our relationships with others are optimal, our overall emotional, and mental health is better.</p>
                                    <p>Whether you are an individual, in a relationship or a family and feel that you are struggling, we can help.</p>
                                    <div className='pt-2'>
                                        <a className="btn btn-theme-solid rounded px-3 py-2" href="#about" rel='noreferrer'>How I Can Help You</a>
                                    </div>
                                </Container>
                            </div>
                        </Col>
                    </Row>
                    <img className="header-kylie-image" src={Kylie1} alt='Kylie' />
                </div>
            </section>


            <section id="about" className="section-small d-flex align-items-center py-5" data-aos="fade-left">
                <Container>
                    <div className='py-3'>
                        <Row className="d-flex flex-md-row">
                            <Col md={6} className='d-flex justify-content-center align-items-center'>
                                <div className="bowl-image mb-md-0"></div>
                            </Col>
                            <Col md={6} className="d-flex flex-column justify-content-center">
                                <h1 className='section-title'>Why Kintsugi</h1>
                                <p className=''><em>Kintsugi is the Japanese philosophy of transforming that which is broken or flawed (usually pottery), into a piece of art by repairing it with gold lacquer.</em></p>
                                <p className='pt-2'>
                                    I resonate with this philosophy and think it highlights the beautiful, transformative ability of therapy.
                                    <br /><br />
                                    The end goal is not to go back to how you once were, but to develop a greater understanding of yourself and others such that your relationships are transformed into stronger, healthier, more beautiful versions.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <section id='kylie' className="section-small d-flex align-items-center bg-theme me-section" data-aos="fade-right">
                <div className=''>
                    <Row className="d-flex flex-column-md-reverse flex-md-row g-5">
                        <Col md={5} className='d-flex justify-content-center align-items-center'>
                            <div className="about-image mb-md-0"></div>
                            <img className="about-image-small" src={AboutSmall} alt='Kylie' loading='lazy' />
                        </Col>
                        <Col md={7} className="d-flex flex-column justify-content-center">
                            <Container>
                                <div className='pt-0 pb-5 py-md-5'>
                                    <h1 className='section-title'>About Me</h1>
                                    <div className='lead-normal'>
                                        <p className=''>
                                            Kylie is curious about people and has many years of experience working with individuals and couples in Australia and overseas. She is passionate about, and dedicated to the growth and development of all her clients.
                                        </p>
                                        <p className=''>
                                            Kylie works in a gentle, person-centred, compassionate, and solution-focussed way. She employs different theories and techniques to get the best for her clients. She believes in the philosophy of Kintsugi:  that repair is a process and that the end product, whilst altered from the original state, can be transformed into a thing of beauty.
                                        </p>
                                        <p className=''>
                                            Kylie has four grown children and too many pets. When not counselling, she is listening to podcasts, cooking, travelling, and spending time with friends.
                                        </p>
                                        <p className=''>
                                            Kylie is a registered counsellor with both Australian Counselling Association and the Psychotherapy and Counselling Federation of Australia. Kylie is also PPP parenting facilitator and a PPP FearLess (for anxious children) facilitator. She has the following qualifications:
                                        </p>
                                        <ul>
                                            <li>MA. Couns UQ</li>
                                            <li>Grad. Dip. Psychology UQ</li>
                                            <li>BA. Psych UQ</li>
                                        </ul>
                                        <div className='d-flex'>
                                            <img src={ACA} className='about-img-small' alt='ACA member' loading='lazy' />
                                        </div>
                                        <img src={PACFA} className='about-img-small' alt='PACFA member' loading='lazy' />
                                    </div>
                                </div>
                            </Container>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="section-small d-flex align-items-center py-5 quote-section" data-aos="fade-left">
                <Container>
                    <div className='py-5'>
                        <Row className="d-flex flex-md-row">
                            <Col md={8} className="d-flex flex-column justify-content-center">
                                <h2 className='quote-text'>
                                    <em>“The longest journey begins with a single step”</em> – Lao Tzu
                                </h2>
                            </Col>
                        </Row>
                        <img className="quote-plate-image" src={Plate} alt='Kintsugi Plate' loading='lazy' />
                    </div>
                </Container>
            </section>

            <section id="services" className="section-medium d-flex align-items-center py-5" data-aos="fade-down">
                <Container>
                    <div className=''>
                        <Row className="d-flex flex-column-reverse flex-md-row">
                            <Col md={12} className="d-flex flex-column justify-content-center">
                                <div className=''>
                                    <h1 className='section-title pb-3 text-center'>What We Offer</h1>
                                    <Row className="d-flex flex-column-reverse flex-md-row g-4">
                                        <Col md={4}>
                                            <div className="">
                                                <img className="card-img-top" src={Individual} alt="Individual services" />
                                                <div className="card-body pt-3">
                                                    <h5 className="card-title">Individuals</h5>
                                                    <p className="card-text pt-2">We offer personalised counselling for individuals seeking to improve mental health and well-being.</p>
                                                    <CSSTransition
                                                        in={showMoreIndividual}
                                                        timeout={300}
                                                        classNames="slide"
                                                        unmountOnExit
                                                    >
                                                        <ul className='hyphen-list'>
                                                            <li>Depression</li>
                                                            <li>Anxiety</li>
                                                            <li>Relationships</li>
                                                            <li>Feeling lost</li>
                                                            <li>Boundary setting</li>
                                                        </ul>
                                                    </CSSTransition>
                                                    <button onClick={() => setShowMoreIndividual(!showMoreIndividual)} className="show-btn">
                                                        {showMoreIndividual ? (
                                                            <p className='pb-0 mb-0'>Show less&nbsp;<i className="fa-solid fa-chevron-up"></i></p>
                                                        ) : (
                                                            <p className='pb-0 mb-0'>Learn more&nbsp;<i className="fa-solid fa-chevron-down"></i></p>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="">
                                                <img className="card-img-top" src={Couple} alt="Couple services" />
                                                <div className="card-body pt-3">
                                                    <h5 className="card-title">Couples</h5>
                                                    <p className="card-text pt-2">Our couple counselling services aim to strengthen your relationship and resolve conflicts.</p>
                                                    <CSSTransition
                                                        in={showMoreCouple}
                                                        timeout={300}
                                                        classNames="slide"
                                                        unmountOnExit
                                                    >
                                                        <ul className='hyphen-list'>
                                                            <li>Pre-marital</li>
                                                            <li>Relationship difficulties</li>
                                                            <li>Infidelity</li>
                                                            <li>Separation/divorce</li>
                                                            <li>Uncoupling</li>
                                                        </ul>
                                                    </CSSTransition>
                                                    <button onClick={() => setShowMoreCouple(!showMoreCouple)} className="show-btn">
                                                        {showMoreCouple ? (
                                                            <p className='pb-0 mb-0'>Show less&nbsp;<i className="fa-solid fa-chevron-up"></i></p>
                                                        ) : (
                                                            <p className='pb-0 mb-0'>Learn more&nbsp;<i className="fa-solid fa-chevron-down"></i></p>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className="">
                                                <img className="card-img-top" src={Family} alt="Family services" />
                                                <div className="card-body pt-3">
                                                    <h5 className="card-title">Families</h5>
                                                    <p className="card-text pt-2">We help families navigate through challenges and improve overall family dynamics.</p>
                                                    <CSSTransition
                                                        in={showMoreFamily}
                                                        timeout={300}
                                                        classNames="slide"
                                                        unmountOnExit
                                                    >
                                                        <ul className='hyphen-list'>
                                                            <li>Post separation</li>
                                                            <li>Parenting</li>
                                                            <li>Communication</li>
                                                        </ul>
                                                    </CSSTransition>
                                                    <button onClick={() => setShowMoreFamily(!showMoreFamily)} className="show-btn">
                                                        {showMoreFamily ? (
                                                            <p className='pb-0 mb-0'>Show less&nbsp;<i className="fa-solid fa-chevron-up"></i></p>
                                                        ) : (
                                                            <p className='pb-0 mb-0'>Learn more&nbsp;<i className="fa-solid fa-chevron-down"></i></p>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <section id='counselling' className="section-small d-flex align-items-center py-5" data-aos="fade-left">
                <Container>
                    <div className=''>
                        <Row className="d-flex flex-md-row">
                            <Col lg={5} className='d-flex justify-content-lg-start pb-4 pb-lg-0'>
                                <img className="service-img shadow-dark" src={CounsellingIndv} alt="Individual Counselling" loading='lazy' />
                            </Col>
                            <Col lg={7} className="d-flex flex-column justify-content-center">
                                <h1 className='section-title'>Counselling for Individuals</h1>
                                <p className='lead-normal'>
                                    We are committed to your growth and development and provide a safe, supportive space that allows you to process and develop your own coping strategies to help manage distressing and overwhelming life situations or states. We can help by:
                                </p>
                                <ul className='lead-normal'>
                                    <li>Supporting you through life transitions, such as, changes in employment or relationship status, empty nesting, retirement, family changes</li>
                                    <li>Supporting and safe-guarding your emotional wellbeing if you are feeling depressed, anxious, withdrawn, disconnected, directionless or alone</li>
                                    <li>Helping with strategies and insights to help improve your interpersonal relationships</li>
                                    <li>Gain insight and understanding around your triggers and reactions</li>
                                    <li>Implement the necessary steps to take back control of your life</li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <section className="section-small d-flex align-items-center py-5" data-aos="fade-right">
                <Container>
                    <div className=''>
                        <Row className="d-flex flex-column-reverse flex-row-reverse flex-lg-row">
                            <Col lg={7} className="d-flex flex-column justify-content-center">
                                <h1 className='section-title'>Couples Counselling</h1>
                                <p className='lead-normal'>
                                    Relationships are the cornerstone of the human experience, and the quality of those relationships not only determine our level of happiness but have a profound impact on mental health and well-being.The right professional help can assist in enriching those relationships that are struggling or gently dissolve those no longer serving you. Kylie believes in approaching both situations with grace and kindness.
                                </p>
                                <p className='lead-normal'>
                                    Research has shown that 70% of couples that complete a course of couples counselling show improvements in their relationship and 90% report increases in emotional wellbeing.
                                </p>
                            </Col>
                            <Col md={5} className='d-flex justify-content-lg-end pb-4 pb-lg-0'>
                                <img className="service-img shadow-dark" src={CounsellingCpl} alt="Couples Counselling" loading='lazy' />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <section className="section-small d-flex align-items-center bg-theme me-section" data-aos="fade-right">
                <div className=''>
                    <Row className="d-flex flex-column-md-reverse flex-md-row g-5">
                        <Col md={6} className='d-flex justify-content-center align-items-center'>
                            <div className="faq-image mb-md-0"></div>
                            <img className="faq-image-small" src={FaqSmall} alt='Kylie' />
                        </Col>
                        <Col md={6} className="d-flex flex-column justify-content-center">
                            <Container>
                                <div className='pt-0 pb-5 py-md-5'>
                                    <h1 className='section-title'>Counselling FAQ</h1>
                                    <div className='lead-normal'>
                                        <p className=''>
                                            We can help to bring understanding and clarity to your personal or relational situation. Discover answers to frequently asked questions about couples counselling.
                                        </p>
                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>How long will it take?</Accordion.Header>
                                                <Accordion.Body>
                                                    Given the individual and unique nature of relationships, there aren’t a set number of sessions and there is also no prescribed course of action. Sometimes a series of sessions might be recommended to create sufficient opportunity to address issues.
                                                    <br /><br />
                                                    The amount of time taken to attend to your relationship depends on what couples want to achieve and the commitment they want to and are able to make to the process. Decisions about this are in your hands, but it is always desirable to discuss this with the counsellor.
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>How does relationship therapy work?</Accordion.Header>
                                                <Accordion.Body>
                                                    Couple therapists use varied approaches informed by different perspectives. A consistent feature of couple counselling is that the relationship between partners, the connections, and disconnections are worked with. Therapy is an active, interactive process that takes time. The couple chooses the ‘agenda’ or issues worked with. There is no one-size-fits-all approach.
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>What is the difference between relationship counselling and other types of counselling?</Accordion.Header>
                                                <Accordion.Body>
                                                    Relationship counsellors are specifically trained to address the dynamics of a relationship as well as the experiences of the individuals who are part of it.
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>Do we both need to come?</Accordion.Header>
                                                <Accordion.Body>
                                                    Couples are usually encouraged to attend counselling together unless there are safety concerns such as the presence of Family Violence. When you have identified the issue as a relationship issue by coming together, you bring your relationship as well as your individual experience. If your partner is unwilling to attend, relationship issues can still be addressed through individual counselling.
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>How much does it cost?</Accordion.Header>
                                                <Accordion.Body>
                                                    Relationship counselling can be accessed both privately or through an agency. Costs of relationship counselling vary.
                                                    <ul>
                                                        <li>There are government-subsidized agencies that establish fees according to income.</li>
                                                        <li>Private practitioners can be contacted directly, and costs can be discussed as part of your initial inquiry.</li>
                                                    </ul>
                                                    Inquire about fees in advance, but note that good therapists do not necessarily charge the highest fees. Find out whether a rebate is possible through a private health fund.
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="5">
                                                <Accordion.Header>Do we have individual sessions?</Accordion.Header>
                                                <Accordion.Body>
                                                    Sessions are for both to attend, although individual sessions with each of you may be arranged as part of the assessment process or may be requested for some other reason. This would usually be discussed in a joint session unless there are safety concerns.
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="6">
                                                <Accordion.Header>How do I get my partner to come to counselling?</Accordion.Header>
                                                <Accordion.Body>
                                                    It is normal for one partner to be more willing to seek relationship help than the other. The wish to seek help is a sign of value being placed on the relationship, but reluctant partners can only be invited to attend. An initial appointment is an opportunity to explore whether you both feel counselling has the potential to be helpful.
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="7">
                                                <Accordion.Header>Might we be advised to separate?</Accordion.Header>
                                                <Accordion.Body>
                                                    Clients are encouraged to make their own decisions. If separation is on your mind, it needs to be considered. The counsellor assists couples in finding the most constructive outcome possible.
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="8">
                                                <Accordion.Header>Can a relationship recover from an affair?</Accordion.Header>
                                                <Accordion.Body>
                                                    The discovery or disclosure of an affair is very challenging. Many factors influence whether a relationship can recover. It can be a time when underlying issues in the relationship come to light, offering an opportunity, after addressing the initial distress, for the relationship to grow and be strengthened. Some relationships do recover, and having support during this time is beneficial.
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="9">
                                                <Accordion.Header>How can a stranger help us?</Accordion.Header>
                                                <Accordion.Body>
                                                    Relationship counsellors are trained to offer a confidential, non-judgemental, and supportive approach. While your experience is unique, common issues are familiar to counsellors, and they are accustomed to helping people address them.
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="10">
                                                <Accordion.Header>How would you know what’s best for us?</Accordion.Header>
                                                <Accordion.Body>
                                                    You are the ones who decide what is best for you. Counselling helps you better understand yourself, your partner, and your relationship to inform decisions about what is best.
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="11">
                                                <Accordion.Header>Does the gender of the therapist matter?</Accordion.Header>
                                                <Accordion.Body>
                                                    The counsellor seeks to maintain neutrality. You or your partner may have a personal preference for a therapist's gender, which can be discussed when choosing a counsellor.
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="12">
                                                <Accordion.Header>Can we be sure the counsellor is not biased?</Accordion.Header>
                                                <Accordion.Body>
                                                    Relationship counsellors focus on understanding both individuals involved without judgement or bias. If you feel the counsellor is biased, it should be discussed in the session.
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="13">
                                                <Accordion.Header>Do I need a referral?</Accordion.Header>
                                                <Accordion.Body>
                                                    A referral is not required. Sometimes your GP or other professionals you are engaged with can make recommendations.
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="14">
                                                <Accordion.Header>Will counselling be confidential?</Accordion.Header>
                                                <Accordion.Body>
                                                    All personal information discussed in sessions remains confidential except when:
                                                    <ul>
                                                        <li>It is subpoenaed in court</li>
                                                        <li>Failure to disclose information would put you or someone else at risk</li>
                                                        <li>Your counsellor has your prior approval to discuss material with another person (e.g., a GP or a family member)</li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="15">
                                                <Accordion.Header>Does the counsellor take notes?</Accordion.Header>
                                                <Accordion.Body>
                                                    Counsellors are obliged to take notes during or after sessions, which are kept secure and covered by confidentiality rules.
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="16">
                                                <Accordion.Header>Do I have to prepare notes before a session?</Accordion.Header>
                                                <Accordion.Body>
                                                    There is no need to prepare notes before sessions. However, you may find it helpful to clarify your thoughts by writing and using your notes as a reference during the session.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </Container>
                        </Col>
                    </Row>
                </div>
            </section>

            <section id="therapies" className="section-large d-flex align-items-center py-5" data-aos="fade-up">
                <Container>
                    <div className='text-center techniques-text mx-auto'>
                        <h1 className='section-title'>Therapies and Techniques We Employ</h1>
                        <p>To achieve progressive results through each session, Kylie employs a range of evidence based therapeutic techniques, helping guide you through your challenges towards positive solutions.</p>
                    </div>
                    <div className='pt-3'>
                        <Row className="d-flex flex-md-row gx-4">
                            <Col md={6} lg={4} className='d-flex justify-content-center align-items-center'>
                                <div className='service-card'>
                                    <div className=''>
                                        <i className="fa-solid fa-brain service-card-icon"></i>
                                        <h2 className='service-card-title'>Cognitive-behavioural</h2>
                                        <p className='service-card-content'>The idea that our thoughts, feelings, and behaviors are interconnected, and that changing negative thoughts and behaviors can lead to improved emotional well-being and functioning.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} lg={4} className='d-flex justify-content-center align-items-center'>
                                <div className='service-card'>
                                    <div className=''>
                                        <i className="fa-solid fa-people-arrows service-card-icon"></i>
                                        <h2 className='service-card-title'>Attachment</h2>
                                        <p className='service-card-content'>Aims to help individuals understand and resolve issues stemming from insecure or disrupted attachments in childhood.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} lg={4} className='d-flex justify-content-center align-items-center'>
                                <div className='service-card'>
                                    <div className=''>
                                        <i className="fa-solid fa-circle-check service-card-icon"></i>
                                        <h2 className='service-card-title'>Solution-focused</h2>
                                        <p className='service-card-content'>A goal-oriented and future-focused therapeutic approach that emphasises finding solutions to current problems rather than delving into past issues.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} lg={4} className='d-flex justify-content-center align-items-center'>
                                <div className='service-card'>
                                    <div className=''>
                                        <i className="fa-solid fa-thumbs-up service-card-icon"></i>
                                        <h2 className='service-card-title'>Motivational interviewing</h2>
                                        <p className='service-card-content'>A client-centered, directive therapeutic approach designed to enhance an individual's motivation to change by exploring and resolving ambivalence.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} lg={4} className='d-flex justify-content-center align-items-center'>
                                <div className='service-card'>
                                    <div className=''>
                                        <i className="fa-solid fa-handshake-simple service-card-icon"></i>
                                        <h2 className='service-card-title'>Acceptance and Commitment</h2>
                                        <p className='service-card-content'>A type of psychotherapy that combines mindfulness and behavioral strategies to help individuals accept and manage their thoughts and feelings.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} lg={4} className='d-flex justify-content-center align-items-center'>
                                <div className='service-card'>
                                    <div className=''>
                                        <i className="fa-solid fa-shoe-prints service-card-icon"></i>
                                        <h2 className='service-card-title'>Narrative</h2>
                                        <p className='service-card-content'>Collaboration to explore and reframe life and experience stories, helping to separate people from their problems and see their lives from new perspectives.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <section className='bg-blue-theme py-5' data-aos="fade">
                <Container>
                    <div className='d-flex align-items-center justify-content-center'>
                        <div className='text-center pt-5'>
                            <h2 className='sessions-text text-white'>Counselling sessions available in-person and online</h2>
                            <div className='pt-2'>
                                <a className="btn btn-theme-solid rounded px-3 py-2" href="https://mindsite.au3.cliniko.com/bookings?business_id=1377204760339291952&practitioner_id=1376503921266138295" target='_blank' rel='noreferrer'>Book an appointment</a>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <section className='border-bottom bg-blue-theme py-5' data-aos="fade">
                <Container>
                    <div className='d-flex align-items-center justify-content-center'>
                        <div className='text-center'>
                            <h2 className='text-white mx-auto'>Customer Testimonials</h2>
                            <p className='lead-normal techniques-text text-white'>Our clients' success stories speak volumes about our dedication and expertise. Discover how our services have made a positive impact through their reviews.</p>
                        </div>
                    </div>
                    <div className=''>
                        <Row className="d-flex flex-md-row g-4">
                            <Col lg={6} className='d-flex justify-content-center align-items-center'>
                                <div className='review-card'>
                                    <div className=''>
                                        <div className="review-author align-items-center">
                                            <div className="review-circle-item align-items-center">
                                                <div className="review-circle circle-icon review-circle-blue">G</div>
                                            </div>
                                            <div>
                                                <p className="mb-1"><span className="review-author-name">Customer G</span><br /><i className="fa-solid fa-star rating-stars"></i><i className="fa-solid fa-star rating-stars"></i><i className="fa-solid fa-star rating-stars"></i><i className="fa-solid fa-star rating-stars"></i><i className="fa-solid fa-star rating-stars"></i></p>
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            <p className='review-card-content'>Kylie helped me understand how I was approaching conflict with those closest to me in a way that wasn’t helpful or constructive, it only pushed people away. I was feeling isolated, confused and hurt as to why I was losing people until Kylie gave me tools to approach my relationships and conflict in a healthier way.</p>
                                            <p className='review-card-content'>Since then, I have not only strengthened my relationships with others, but I’ve also felt a lot more at peace with myself in all aspects of my life.</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} className='d-flex justify-content-center align-items-center'>
                                <div className='review-card'>
                                    <div className=''>
                                        <div className="review-author align-items-center">
                                            <div className="review-circle-item align-items-center">
                                                <div className="review-circle circle-icon review-circle-yellow">K</div>
                                            </div>
                                            <div>
                                                <p className="mb-1"><span className="review-author-name">Customer K and E</span><br /><i className="fa-solid fa-star rating-stars"></i><i className="fa-solid fa-star rating-stars"></i><i className="fa-solid fa-star rating-stars"></i><i className="fa-solid fa-star rating-stars"></i><i className="fa-solid fa-star rating-stars"></i></p>
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            <p className='review-card-content'>My partner and I felt as if many couple counsellors we had seen in the past only told us what we wanted to hear and didn’t really take the time to crack us until Kylie. We felt as if she could see through any of our false responses and could call us out on it so that we could work through the real issues at hand.</p>
                                            <p className='review-card-content'>Since then, my partner and I have had elective and helpful sessions with Kylie where all of us are a lot more honest with ourselves and each other.</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <section id="contact" className="section-medium pt-5 pb-4" data-aos="fade-right">
                <Container>
                    <Row className="g-5">
                        <Col lg={6} className="d-flex flex-column justify-content-center">
                            <div className='pb-0 mb-0'>
                                <h1 className='section-title'>Get in Touch With Us</h1>
                                <p className="">
                                    Need to enquire further or book an appointment? Don't hesitate to get in touch with us via our contact links below, inquiry form, or make a booking through our link.
                                </p>
                                <InquiryForm />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <img src={Appointment} className='appointment-image' alt="Kylie Appointment" loading='lazy' />
                            <div className='pt-3'>
                                <p className='contact-lead'>
                                    Kintsugi Counselling
                                </p>
                                <p className="">
                                    Need to enquire further or book an appointment? Don't hesitate to get in touch with us via our contact links below, inquiry form, or make a booking through our link.
                                </p>
                            </div>
                            <div className='pt-2'>
                                <p className='contact-lead'>
                                    Contact Info
                                </p>
                                <ul className='top-contact-list'>
                                    <li><strong>Email:</strong>&nbsp; <a href="mailto:kylie@kintsugicounselling.com" target="_blank" rel="noreferrer">kylie@kintsugicounselling.com</a></li>
                                    <li><strong>Phone:</strong>&nbsp; <a href="tel:1300 008 616" target="_blank" rel="noreferrer">1300 008 616</a></li>
                                    <li><strong>Location:</strong>&nbsp; <a href="https://www.google.com/maps/place/Shop+2A%2F417+Logan+Rd,+Greenslopes+QLD+4120/@-27.501326,153.0445417,15.84z/data=!4m5!3m4!1s0x6b915a5b49f5b0cb:0xcb5fc57ed1025c40!8m2!3d-27.5008941!4d153.0455942?entry=ttu" target="_blank" rel="noreferrer">Shop 2A, 417 Logan Road, Stones Corner, QLD, 4120</a></li>
                                </ul>
                                <a className="btn btn-theme-solid rounded px-3 py-2 mt-2" href="https://mindsite.au3.cliniko.com/bookings?business_id=1377204760339291952&practitioner_id=1376503921266138295" target='_blank' rel='noreferrer'>Book an appointment</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="section-medium pt-5 pb-4" data-aos="fade">
                <Container>
                    <div className='map-container pt-4 mb-3 mb-md-0'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3538.9833239255317!2d153.04301927546007!3d-27.500894076301524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b915a5b49f5b0cb%3A0xcb5fc57ed1025c40!2sShop%202A%2F417%20Logan%20Rd%2C%20Greenslopes%20QLD%204120!5e0!3m2!1sen!2sau!4v1718690810830!5m2!1sen!2sau" width="100%" height="500" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='Shop 2A, 417 Logan Road, Stones Corner, QLD, 4120'></iframe>
                    </div>
                    <Row className="g-4 pt-4">
                        <Col md={4} className="d-flex flex-column justify-content-center">
                            <img src={P4} className='bottom-image shadow' alt='Relationship Counselling' loading='lazy' />
                        </Col>
                        <Col md={4} className="d-flex flex-column justify-content-center">
                            <img src={P5} className='bottom-image shadow' alt='The Mindsite' loading='lazy' />
                        </Col>
                        <Col md={4} className="d-flex flex-column justify-content-center">
                            <img src={P6} className='bottom-image shadow' alt='Counselling Office' loading='lazy' />
                        </Col>
                    </Row>
                </Container>
            </section>

            <Footer />
        </>
    )
};

export default HomePage;