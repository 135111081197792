import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import LoadingOverlay from './LoadingOverlay';
import SuccessModal from './SuccessModal';
import ErrorModal from './ErrorModal';

const InquiryForm = () => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const formData = new FormData(event.target);

        const data = {
            name: formData.get('name'),
            email: formData.get('email'),
            contact: formData.get('contact'),
            inquiryType: formData.get('inquiryType'),
            message: formData.get('message'),
        };

        try {
            const response = await fetch('https://formsubmit.co/ajax/kylie@kintsugicounselling.com', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            await response.json();
            setLoading(false);
            setSuccess(true);
            event.target.reset();
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
            setError(true);
        }
    };

    return (
        <>
            <LoadingOverlay show={loading} />
            <SuccessModal show={success} handleClose={() => setSuccess(false)} />
            <ErrorModal show={error} handleClose={() => setError(false)} />
            <div className="d-flex justify-content-center pt-4">
                <div className="form-container">
                    <form id='enquiry-form' onSubmit={handleSubmit}>
                        {/* <!-- Box Email Template --> */}
                        <input type="hidden" name="_template" value="box" />

                        {/* <!-- Honey Pot --> */}
                        <input type="text" name="_honey" style={{ display: 'none' }} />

                        {/* <!-- Disable Captcha --> */}
                        <input type="hidden" name="_captcha" value="false" />

                        {/* <!-- Email Subject --> */}
                        <input type="hidden" name="_subject" value="New Website Submission" />
                        <Row className='d-flex flex-md-row'>
                            <Col md={6}>
                                <div className="form-group">
                                    <label className='form-label'>First name <span className='form-required'>*</span></label>
                                    <input type="text" className="form-control" name='name' placeholder='Jane*' required />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="form-group">
                                    <label className='form-label'>Last name <span className='form-required'>*</span></label>
                                    <input type="text" className="form-control" name='name' placeholder='Doe*' required />
                                </div>
                            </Col>
                        </Row>
                        <div className="form-group">
                            <label className='form-label'>Email <span className='form-required'>*</span></label>
                            <input type="email" className="form-control" name='email' placeholder='janedoe@company.com*' required />
                        </div>
                        <div className="form-group">
                            <label className='form-label'>Contact number <span className='form-required'>*</span></label>
                            <input type="tel" className="form-control" name='contact' placeholder='eg. 0412 345 678*' required />
                        </div>
                        <div className="form-group">
                            <label className='form-label'>Type of enquiry <span className='form-required'>*</span></label>
                            <select className="form-control" name='inquiryType' defaultValue="" required>
                                <option value="general" selected>General enquiry</option>
                                <option value="cognitive-behavioural">Cognitive-behavioural</option>
                                <option value="attachment">Attachment</option>
                                <option value="solution-focused">Solution-focused</option>
                                <option value="motivational-interviewing">Motivational interviewing</option>
                                <option value="acceptance-and-commitment">Acceptance and Commitment</option>
                                <option value="narrative">Narrative</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label className='form-label'>Your message <span className='form-required'>*</span></label>
                            <textarea className="form-control" placeholder="Message details" rows="4" name='message' required></textarea>
                        </div>
                        <button type="submit" className="btn btn-theme-green-solid-form rounded px-3 py-2">Submit inquiry</button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default InquiryForm;
