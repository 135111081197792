import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import FooterLogo from '../img/logo-updated-2.png';
import '../App.css';
import smoothScroll from '../smoothScroll';

const Footer = () => {
    const handleNavClick = (e, targetId) => {
        e.preventDefault();
        smoothScroll(targetId);
    };

    return (
        <footer className="footer pt-5 pb-3">
            <Container>
                <hr className='mt-1 mb-5' />
                <Row className="flex-row">
                    <Col md={12} xl={6} className="d-flex pb-4">
                        <div className="footer-content">
                            <div className=''>
                                <img src={FooterLogo} className='footer-logo' alt="Kintsugi Counselling" />
                            </div>
                        </div>
                    </Col>
                    <Col md={6} xl={3}>
                        <div className="footer-contact">
                            <h4 className='section-title-smaller'>Explore the site</h4>
                            <div className=''>
                                <button className="footer-link" onClick={(e) => handleNavClick(e, 'about')}>About</button><br />
                                <button className="footer-link" onClick={(e) => handleNavClick(e, 'kylie')}>Kylie</button><br />
                                <button className="footer-link" onClick={(e) => handleNavClick(e, 'services')}>Services</button><br />
                                <button className="footer-link" onClick={(e) => handleNavClick(e, 'counselling')}>Counselling</button><br />
                                <button className="footer-link" onClick={(e) => handleNavClick(e, 'therapies')}>Therapies</button><br />
                                <button className="footer-link" onClick={(e) => handleNavClick(e, 'contact')}>Contact</button><br />
                            </div>
                        </div>
                    </Col>
                    <Col md={6} xl={3}>
                        <div className="footer-contact pt-3 pt-md-0">
                            <h4 className='section-title-smaller'>Contact Us</h4>
                            <ul className='top-contact-list'>
                                <li><strong>Email:</strong>&nbsp; <a href="mailto:kylie@kintsugicounselling.com" target="_blank" rel="noreferrer">kylie@kintsugicounselling.com</a></li>
                                <li><strong>Phone:</strong>&nbsp; <a href="tel:1300 008 616" target="_blank" rel="noreferrer">1300 008 616</a></li>
                                <li><strong>Location:</strong>&nbsp; <a href="https://www.google.com/maps/place/Shop+2A%2F417+Logan+Rd,+Greenslopes+QLD+4120/@-27.501326,153.0445417,15.84z/data=!4m5!3m4!1s0x6b915a5b49f5b0cb:0xcb5fc57ed1025c40!8m2!3d-27.5008941!4d153.0455942?entry=ttu" target="_blank" rel="noreferrer">Shop 2A, 417 Logan Road, Stones Corner, QLD, 4120</a></li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row className='flex-row'>
                    <Col md={12}>
                        <hr className='mt-4 mb-3' />
                        <div className='d-flex justify-content-center'>
                            Copyright &copy; 2024 | Kintsugi Counselling | All Rights Reserved.
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
