import React, { useEffect, useState } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import '../App.css';
import NavLogo from '../img/logo-updated-white.png';
import smoothScroll from '../smoothScroll';

const NavigationBar = () => {
    // const [show, setShow] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
    const [activeSection, setActiveSection] = useState('home');
    const [inHomeSection, setInHomeSection] = useState(true);
    const [expanded, setExpanded] = useState(false);

    const handleNavClick = (e, targetId) => {
        e.preventDefault();
        smoothScroll(targetId);
        setExpanded(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            const isScrollingUp = prevScrollPos > currentScrollPos;

            // setShow(isScrollingUp || currentScrollPos < 10);
            setPrevScrollPos(currentScrollPos);

            if ((isScrollingUp && expanded) || (!isScrollingUp && expanded)) {
                setExpanded(false);
            }

            const sections = ['home', 'about', 'counselling', 'services', 'therapies', 'kylie', 'contact'];
            const sectionPositions = sections.map(section => {
                const element = document.getElementById(section);
                if (element) {
                    const rect = element.getBoundingClientRect();
                    return {
                        section,
                        midPoint: rect.top + rect.height / 2
                    };
                }
                return null;
            }).filter(item => item !== null);

            const viewportMidPoint = window.innerHeight / 2;

            const closestSection = sectionPositions.reduce((closest, current) => {
                if (Math.abs(current.midPoint - viewportMidPoint) < Math.abs(closest.midPoint - viewportMidPoint)) {
                    return current;
                }
                return closest;
            }, sectionPositions[0]);

            setActiveSection(closestSection.section);

            const homeSection = document.getElementById('home');
            if (homeSection) {
                const rect = homeSection.getBoundingClientRect();
                setInHomeSection(rect.top <= 0 && rect.bottom >= window.innerHeight);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [prevScrollPos, inHomeSection, expanded]);

    return (
        <Navbar
            expand="xxl"
            className={`navbar-transparent`}
            expanded={expanded}
            onToggle={() => setExpanded(!expanded)}
        >
            <Container>
                <Navbar.Brand onClick={(e) => handleNavClick(e, 'home')}>
                    <img src={NavLogo} className='nav-logo' alt="Nav logo" loading='eager' />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav">
                    <i className={`fa-solid fa-bars text-white bars ${!expanded ? 'show' : ''}`}></i>
                    <i className={`fa-solid fa-x text-white exit ${expanded ? 'show' : ''}`}></i>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
                    <Nav className="">
                        <Nav.Link onClick={(e) => handleNavClick(e, 'about')} className={activeSection === 'about' ? 'active' : ''}>About</Nav.Link>
                        <Nav.Link onClick={(e) => handleNavClick(e, 'kylie')} className={activeSection === 'kylie' ? 'active' : ''}>Kylie</Nav.Link>
                        <Nav.Link onClick={(e) => handleNavClick(e, 'services')} className={activeSection === 'services' ? 'active' : ''}>Services</Nav.Link>
                        <Nav.Link onClick={(e) => handleNavClick(e, 'counselling')} className={activeSection === 'counselling' ? 'active' : ''}>Counselling</Nav.Link>
                        <Nav.Link onClick={(e) => handleNavClick(e, 'therapies')} className={activeSection === 'therapies' ? 'active' : ''}>Therapies</Nav.Link>
                        <Nav.Link onClick={(e) => handleNavClick(e, 'contact')} className={activeSection === 'contact' ? 'active' : ''}>Contact</Nav.Link>
                        <div className='pt-2 pt-lg-0 nav-btn'>
                            <a className="btn btn-theme-solid rounded px-3 py-2" href="https://mindsite.au3.cliniko.com/bookings?business_id=1377204760339291952&practitioner_id=1376503921266138295" target='_blank' rel='noreferrer'>Make a booking</a>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        // <Navbar
        //     bg="white"
        //     expand="lg"
        //     fixed="top"
        //     className={`navbar-custom border-bottom shadow-sm ${show ? '' : 'hidden'}`}
        //     expanded={expanded}
        //     onToggle={() => setExpanded(!expanded)}
        // >
        //     <Container>
        //         <Navbar.Brand onClick={(e) => handleNavClick(e, 'home')}>
        //             <img src={NavLogo} className='nav-logo' alt="Nav logo" />
        //         </Navbar.Brand>
        //         <Navbar.Toggle aria-controls="basic-navbar-nav">
        //             <i className="fa-solid fa-bars"></i>
        //         </Navbar.Toggle>
        //         <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
        //             <Nav className="mx-auto">
        //                 <Nav.Link onClick={(e) => handleNavClick(e, 'about')} className={activeSection === 'about' ? 'active' : ''}>About</Nav.Link>
        //                 <Nav.Link onClick={(e) => handleNavClick(e, 'counselling')} className={activeSection === 'counselling' ? 'active' : ''}>Counselling</Nav.Link>
        //                 <Nav.Link onClick={(e) => handleNavClick(e, 'services')} className={activeSection === 'services' ? 'active' : ''}>Services</Nav.Link>
        //                 <Nav.Link onClick={(e) => handleNavClick(e, 'therapies')} className={activeSection === 'therapies' ? 'active' : ''}>Therapies</Nav.Link>
        //                 <Nav.Link onClick={(e) => handleNavClick(e, 'kylie')} className={activeSection === 'kylie' ? 'active' : ''}>Kylie</Nav.Link>
        //                 <Nav.Link onClick={(e) => handleNavClick(e, 'contact')} className={activeSection === 'contact' ? 'active' : ''}>Contact</Nav.Link>
        //             </Nav>
        //             <div className='pt-2 pt-lg-0'>
        //                 <a className="btn btn-theme-green rounded px-3 py-2" href="https://mindsite.au3.cliniko.com/bookings?business_id=1377204760339291952&practitioner_id=1376503921266138295" target='_blank' rel='noreferrer'>Make a booking</a>
        //             </div>
        //         </Navbar.Collapse>
        //     </Container>
        // </Navbar>
    );
};

export default NavigationBar;
