import React from 'react';
import Loader from '../img/favicon.png';
import '../LoadingOverlay.css';

const LoadingOverlay = ({ show }) => {
    if (!show) return null;
    return (
        <div id="loadingOverlay" className="overlay">
            <img id="loaderImage" src={Loader} alt="Loading..." className="loader-image" />
        </div>
    );
};

export default LoadingOverlay;
