import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Logo from '../img/favicon.png';

const ErrorModal = ({ show, handleClose }) => {
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Submission Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-6 offset-3 text-center pb-3">
                        <img className="img-fluid" src={Logo} alt="Kintsugi Counselling" />
                    </div>
                    <div className="col-12 px-5 text-center">
                        Sorry, an error has occurred and your request could not be submitted. If the issue persists, please don't hesitate to contact us via our phone number, email, or social media.
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="theme" onClick={handleClose} className="rounded-pill">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ErrorModal;
